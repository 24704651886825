import React, { useState } from 'react';
import './index.css';
import { StepOne } from './Stepone';
import { Stepthree } from './Stepthree';
import { Steptwo } from './Steptwo';
import { HeaderForm } from './HeaderForm';
import { Stepfour } from './Stepfour';

// Main Form Component
const Consultation = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [fadeClass, setFadeClass] = useState('fade-in');

  const handleStepChange = (direction) => {
    setFadeClass('fade-out');
    setTimeout(() => {
      setCurrentStep((prev) => {
        const newStep = prev + direction;
        if (newStep >= 1 && newStep <= 4) {
          return newStep;
        }
        return prev;
      });
      setFadeClass('fade-in');
    }, 500);
  };

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return <StepOne />;
      case 2:
        return <Steptwo />;
      case 3:
        return <Stepthree />;
      case 4:
        return <Stepfour />;
      default:
        return <StepOne />;
    }
  };

  return (
    <div className=' min-height'>
      <HeaderForm />
      <div className="mt-5 container">
        <div className="justify-content-md-center row g-3">
          <div className='col-md-12'>
            <form className={`w-form ${fadeClass}`}>
              {renderStep()}                
              <div className={
                currentStep === 1 ? 
                "mt-30 p-0 card-footer bg-transparent border-0 d-flex align-items-center justify-content-end gap-2"
                :
                "mt-30 p-0 card-footer bg-transparent border-0 d-flex align-items-center justify-content-between gap-2"
              }>
                {currentStep > 1 && (
                  <button className='border-0 bg-transparent' type='button' onClick={() => handleStepChange(-1)}>
                    <img src="/assets/images/backicon.png" width={35} alt="Back" />
                  </button>
                )}
                {currentStep < 4 && (
                  <button className='btncust w-auto activebg' type='button' onClick={() => handleStepChange(1)}>Next</button>
                )}
                {currentStep === 4 && (
                  <button className='btncust w-auto activebg' type="submit">Submit</button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Consultation;
