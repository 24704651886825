import React, { useState } from 'react';
import '../index.css';

const Stepfour = () => {
  

  return (
    <div className='row g-5'>
        <div className='col-lg-12'>
            <h5 className='mb-5'>
                Some other questions
            </h5>
        </div>
        <div className='col-lg-12'>
            <div class="form-floating mb-3">
                <input type="text" class="form-control rounded-4" id="floatingInput1" placeholder="" />
                <label for="floatingInput1" className='metropill'>Your Name</label>
            </div>
        </div>
        <div className='col-lg-12'>
            <div class="form-floating mb-3">
                <input type="text" class="form-control rounded-4" id="floatingInput2" placeholder="" />
                <label for="floatingInput2" className='metropill'>Email address</label>
            </div>
        </div>
    </div>
  );
}

export { Stepfour };
