import React, { useState } from 'react';
import '../index.css';

const Steptwo = () => {
  const [checkedOne, setCheckedOne] = useState(false);
  const [checkedTwo, setCheckedTwo] = useState(false);

  return (
    <div className='row g-5'>
      <div className='col-lg-12'>
        <h5 className='mb-5'>
          Are you wanting to lose weight?
        </h5>
      </div>
      <div className='col-lg-12'>
        <input type="checkbox" className='d-none' id='one' />
        <label className={checkedOne ? 
            'padc bullet active-check w-100 rounded-3 d-flex align-items-center gap-3' : 
            'padc bullet w-100 d-flex align-items-center gap-3 rounded-3' } 
            onClick={() => setCheckedOne(!checkedOne)} 
            for='one'
        >   
            <div className='label-check'></div>
            <span class="metropill">
              I want an easy and convenient option
            </span>
        </label> 
      </div>
      
      <div className='col-lg-12'>
        <input type="checkbox" className='d-none' id='two' />
        <label className={checkedTwo ? 
            'padc bullet active-check w-100 rounded-3 d-flex align-items-center gap-3' : 
            'padc bullet w-100 d-flex align-items-center gap-3 rounded-3' } 
            onClick={() => setCheckedTwo(!checkedTwo)} 
            for='two'
        >   
            <div className='label-check'></div>
            <span class="metropill">
              Other
            </span>
        </label>
        <div className={checkedOne ? 'd-flex align-items-center mt-3 animatecs active fade-inn' : 'd-flex align-items-center mt-3 animatecs fade-outt'}>
          <img src='/assets/images/error.png' width={16} alt="Error" />
          <p className='text-danger fs-12 m-0 metropill ms-2 pt-2'>Error here!</p>
        </div>
      </div>
    </div>
  );
}

export { Steptwo };
