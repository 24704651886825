import React from 'react';
import Drawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css';
import { Collapse } from 'antd';
import 'antd/dist/reset.css';
import { Link } from 'react-router-dom';
const { Panel } = Collapse;

const DrawerComponent = ({ isOpen, onClose, biomarkerData }) => {
  // Sort biomarkerData alphabetically by the 'name' property
  const sortedBiomarkers = biomarkerData ? 
    [...biomarkerData].sort((a, b) => {
      const nameA = (a.name || a.biomaker || '').toLowerCase();
      const nameB = (b.name || b.biomaker || '').toLowerCase();
      return nameA.localeCompare(nameB);
    }) 
    : [];

  return (
    <Drawer
      open={isOpen}
      onClose={onClose}
      direction="right"
      size={350} 
    >
      <div className="drawer-content">
        <div className='row'>
            <div className='col-6'>
                <h4 className='p-4'>Biomarkers</h4>
            </div>
            <div className='col-6 text-end'>
                <button className='btn-close-icon' onClick={onClose} >X</button>
            </div>
        </div>

        <div className='p-4'>
          {sortedBiomarkers.length > 0 ? (
            <Collapse accordion className="custom-collapse">
              {sortedBiomarkers.map((biomarker, index) => (
                <Panel 
                  className='custom-panel' 
                  header={biomarker.name || biomarker.biomaker}
                  key={index}
                >
                  <p className='metropill p-4'>{biomarker.description}</p>
                </Panel>
              ))}
            </Collapse>
          ) : (
            <p>No details available.</p>
          )}
        </div>
      </div>
    </Drawer>
  );
};

export default DrawerComponent;
