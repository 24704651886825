import React from 'react' 
import Checkout from './pages/checkout/Checkout';
import PageLoader from './components/PageLoader';
// import Cart from './pages/Cart';
import Cart from './pages/cart/Cart';
import AddToCart from './pages/AddToCart';
import AppointmentSuccess from './pages/AppointmentSuccess';
import AppointmentCancel from './pages/AppointmentCancel';
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import DummyPage from './pages/DummyPage';
import BuildMyPkg from './pages/BuildPackage/BuildMyPkg';
// import Consultation from './EdConsultation/Consultation';
import Quiz from './EdConsultation/Quiz';
import Consultation from './pages/consultation/Consultation';
import DummyConsultation from './pages/consultation/DummyConsultation';


const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Checkout/>} />
        <Route path='dumm' element={<DummyPage />} />
        <Route path='/checkout' element={<Checkout/>} />
        <Route path='/loader' element={<PageLoader/>} />
        <Route path='/cart/:uniqueCode' element={<AddToCart/>} />
        <Route path='/cart' element={<Cart/>} />
        <Route path='/appointment/success/:userToken' element={<AppointmentSuccess/>} />
        <Route path='/appointment/cancel' element={<AppointmentCancel/>} />
        <Route path='/build-my-package' element={<BuildMyPkg/>} />
        <Route path='/consultation' element={<DummyConsultation/>} />
        <Route path='/quiz' element={<Consultation/>} />
      </Routes>
    </BrowserRouter>
  )
}

export default App