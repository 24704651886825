// src/components/Quiz.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { get } from "../services/ApiService"; // Assuming you have a post function

// Define validation schema
const validationSchema = Yup.object({
  explanation: Yup.string().when('requireExplanation', {
    is: true,
    then: Yup.string().required('Explanation is required')
  })
});

const Quiz = () => {
  const [questions, setQuestions] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [errors, setErrors] = useState({});
  const [answers, setAnswers] = useState({});

  useEffect(() => {
    const getQuestions = async () => {
      try {
        const res = await get(`api/questions`);
        if (res?.success) {
          setQuestions(res.questions);
        }
      } catch (error) {
        console.error(error);
      } 
    };

    getQuestions();
  }, []);

  const handleNext = (values, { setSubmitting }) => {
    const question = questions[currentStep];
    const answer = values[question._id];
    
    // Validate required fields
    if (question.isRequired && !answer) {
      setErrors(prev => ({ ...prev, [question._id]: 'This question is required' }));
      setSubmitting(false);
      return;
    }
    
    if (question.inputType === 'radio' && !answer) {
      setErrors(prev => ({ ...prev, [question._id]: 'Please select an option' }));
      setSubmitting(false);
      return;
    }
    
    if (question.requireExplanation && !values.explanation) {
      setErrors(prev => ({ ...prev, explanation: 'Explanation is required' }));
      setSubmitting(false);
      return;
    }

    // Validate correct answer
    const correctAnswer = question.options.find(option => option.isCorrectAnswer)?.optionText;
    if (correctAnswer && correctAnswer !== answer) {
      setErrors(prev => ({ ...prev, [question._id]: 'Incorrect answer' }));
      setSubmitting(false);
      return;
    }

    // Save answers
    setAnswers(prev => ({ ...prev, [question._id]: answer }));

    // Clear errors
    setErrors({});
    
    // Go to the next question
    setCurrentStep(prev => Math.min(prev + 1, questions.length - 1));
    setSubmitting(false);
  };

  const handlePrev = () => {
    setCurrentStep(prev => Math.max(prev - 1, 0));
  };

  if (questions.length === 0) {
    return <p>Loading...</p>;
  }

  const currentQuestion = questions[currentStep];

  return (
    <div className="quiz-container">
      <h1>Quiz</h1>
      <Formik
        initialValues={{ [currentQuestion._id]: '', explanation: '' }}
        validationSchema={validationSchema}
        onSubmit={handleNext}
      >
        {({ setFieldValue, values, isSubmitting }) => (
          <Form className="quiz-form">
            <div className="question-container">
              <h2>{currentQuestion.title}</h2>
              {currentQuestion.inputType === 'radio' && currentQuestion.options.map(option => (
                <div key={option.optionText} className="option">
                  <label>
                    <Field type="radio" name={currentQuestion._id} value={option.optionText} />
                    {option.optionText}
                  </label>
                </div>
              ))}
              {currentQuestion.inputType === 'select' && (
                <Field as="select" name={currentQuestion._id} multiple={true}>
                  {currentQuestion.options.map(option => (
                    <option key={option.optionText} value={option.optionText}>
                      {option.optionText}
                    </option>
                  ))}
                </Field>
              )}
              {currentQuestion.inputType === 'text' && (
                <Field type="text" name={currentQuestion._id} />
              )}
              <ErrorMessage name={currentQuestion._id} component="div" className="error-message" />
              {currentQuestion.requireExplanation && (
                <div className="explanation-container">
                  <label>
                    Explanation
                    <Field type="text" name="explanation" />
                  </label>
                  <ErrorMessage name="explanation" component="div" className="error-message" />
                </div>
              )}
              <div className="navigation-buttons">
                <button type="button" onClick={handlePrev} disabled={currentStep === 0} className="nav-button">Previous</button>
                <button type="submit" disabled={isSubmitting} className="nav-button">Next</button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Quiz;
