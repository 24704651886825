import React from 'react';
import './index.css';

const FormSkeleton = ({ width, borderRadius, height, marginLeft, marginRight, }) => {
  return (
    <div
      className="skeleton-block"
      style={{
        width: width || '100%',
        borderRadius: borderRadius || '4px',
        height: height || '20px' ,
        marginLeft: marginLeft || '0px',
        marginRight: marginRight || '0px',
      }}
    />
  );
};

export { FormSkeleton };
