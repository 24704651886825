import React, { useState, useEffect } from 'react';
import moment from 'moment';
import './index.css';
import Spinner from 'react-bootstrap/Spinner';
function OrderSummary({handleSubmit, cartItems, calculateSubtotal, calculateTotal, isSubmit,isBloodDraw,filteredTimeSlots }) {

    const [currentDateTime, setCurrentDateTime] = useState('');
    useEffect(() => {
        const now = moment().format('DD-MM-YYYY h:mm A');
        setCurrentDateTime(now);
    }, []);

    return (
        <>
            <div className='card order-summary'>
                <div className='card-body'>
                    <h4>Order Summary</h4>
                    <div className='row'>
                        <div className='col-4 text-dark'>Date:</div>
                        <div className='col-8  fw-normal metropill text-body-emphasis text-end  '>{currentDateTime}</div>
                        <div className='col-4 text-dark'>Provider:</div>
                        <div className='col-8 fw-normal metropill text-body-emphasis text-end'>My Private Chemist, Longcraft House, 2-8 Victoria Avenue, EC2M 4NS</div>
                    </div>
                    <hr />
                    {cartItems.length > 0 &&
                        <>
                        {cartItems.map((item, index) => (
                        <div className='d-flex flex-grow-1'>
                            <div className="pe-6 theme-font text-dark">
                                {item.name}
                            </div>
                            <div className="ms-auto">
                                <p className="fs-14px text-body-emphasis mb-0 fw-normal metropill">
                                    £{item.type === "Service" ? item.price : item.discountPrice}
                                </p>
                            </div>
                        </div>
                        ))}
                        <hr/>
                        </>
                    }
                    <div className="d-flex align-items-center mb-2 theme-font">
                        <span className="theme-font  text-dark">Subtotal:</span>
                        <span className="d-block ms-auto text-body-emphasis fw-normal metropill">
                        £{calculateSubtotal()}
                        </span>
                    </div>
                    {isBloodDraw && 
                    <div className="d-flex align-items-center mb-2 theme-font">
                        <span className="theme-font  text-dark">Blood Draw fee:</span>
                        <span className="d-block ms-auto text-body-emphasis  fw-normal metropill">
                        £30.00
                        </span>
                    </div>
                    }

                </div>
                <div className="card-footer bg-transparent py-5 px-0 mx-10">
                <div className="d-flex align-items-center fw-bold mb-6">
                    <span className="text-body-emphasis p-0 theme-font fw-bold">
                    Total price:
                    </span>
                    <span className="d-block ms-auto text-body-emphasis fs-4  fw-normal metropill">
                        £{calculateTotal()}
                    </span>
                </div>
                </div>
            </div>
            <div className="d-flex mt-8 text-center" style={{ marginTop: '30px !important' }}>
                <div className="col-auto me-2">
                    <img className="w-60" src="/assets/images/payments/card1.png" alt='' />
                </div>
                <div className="col-auto me-2">
                    <img className="w-70" src="/assets/images/payments/card2.png" alt=''/>
                </div>
                <div className="col-auto me-2">
                    <img className="w-70" src="/assets/images/payments/card3.png" alt='' />
                </div>
                <div className="col-auto me-2">
                    <img className="w-70" src="/assets/images/payments/card4.png" alt='' />
                </div>
                <div className="col-auto me-2">
                    <img className="w-70" src="/assets/images/payments/card5.png" alt='' />
                </div>
            </div>
            <div className="p-3  pb-0 text-center">
            {filteredTimeSlots.length > 0 &&
                <button type="submit" className="btn-show w-100 metropill" disabled={isSubmit} onClick={handleSubmit}>
                    {isSubmit && <Spinner animation="border" variant="light" size="sm" className="mr-2" />} Pay by Credit / Debit
                </button>
            }
            </div>
      </>
    );
}

export default OrderSummary;
