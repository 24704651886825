import React, { useState } from 'react';
import { Modal, Form, Input, Button, DatePicker, message } from 'antd';
import { UserOutlined, MailOutlined, CalendarOutlined, PhoneOutlined, ClockCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import Select from 'react-select';
import './index.css'; // Import custom CSS for styling

const BookingModal = ({ isVisible, onClose, ManualBooking, confirmLoading }) => {
    const [bookingDate, setBookingDate] = useState(null);
    const [bookingTime, setBookingTime] = useState(null);

    // Time options for the select dropdown
    const timeOptions = [
        { value: '09:00', label: '09:00 AM' },
        { value: '09:15', label: '09:15 AM' },
        { value: '09:30', label: '09:30 AM' },
        { value: '09:45', label: '09:45 AM' },
        { value: '10:00', label: '10:00 AM' },
        { value: '10:15', label: '10:15 AM' },
        { value: '10:30', label: '10:30 AM' },
        { value: '10:45', label: '10:45 AM' },
        { value: '11:00', label: '11:00 AM' },
        { value: '11:15', label: '11:15 AM' },
        { value: '11:30', label: '11:30 AM' },
        { value: '11:45', label: '11:45 AM' },
        { value: '12:00', label: '12:00 PM' },
        { value: '12:15', label: '12:15 PM' },
        { value: '12:30', label: '12:30 PM' },
        { value: '12:45', label: '12:45 PM' },
        { value: '13:00', label: '01:00 PM' },
        { value: '13:15', label: '01:15 PM' },
        { value: '13:30', label: '01:30 PM' },
        { value: '13:45', label: '01:45 PM' },
        { value: '14:00', label: '02:00 PM' },
        { value: '14:15', label: '02:15 PM' },
        { value: '14:30', label: '02:30 PM' },
        { value: '14:45', label: '02:45 PM' },
        { value: '15:00', label: '03:00 PM' },
        { value: '15:15', label: '03:15 PM' },
        { value: '15:30', label: '03:30 PM' },
        { value: '15:45', label: '03:45 PM' },
        { value: '16:00', label: '04:00 PM' },
        { value: '16:15', label: '04:15 PM' },
        { value: '16:30', label: '04:30 PM' },
        { value: '16:45', label: '04:45 PM' },
        { value: '17:00', label: '05:00 PM' },
        { value: '17:15', label: '05:15 PM' },
        { value: '17:30', label: '05:30 PM' }
    ];

    // Disable dates before the current date and on weekends
    const disabledDate = (current) => {
        return current && (current < moment().startOf('day') || current.day() === 0 || current.day() === 6);
    };

    // Handle form submission
    const handleFinish = async (values) => {
        try {
            const finalValues = {
                ...values,
                bookingDate: bookingDate,
                bookingTime: bookingTime
            };
            await ManualBooking(finalValues);
        } catch (error) {
            message.error('Failed to send email. Please try again.');
        }
        onClose();
    };

    // Handle date change
    const handleDateChange = (date) => {
        setBookingDate(date);
    };

    // Handle time change
    const handleTimeChange = (selectedOption) => {
        setBookingTime(selectedOption ? selectedOption.value : null);
    };

    return (
        <Modal
            title="Dear My Private Chemist,"
            open={isVisible}
            onCancel={onClose}
            footer={null}
            confirmLoading={confirmLoading}
            destroyOnClose
            width={600}
        >
            <Form
                layout="horizontal"
                onFinish={handleFinish}
                style={{ maxWidth: '100%' }}
            >
                <Form.Item
                    name="additionalInfo"
                    initialValue="I am interested in booking the blood test package I have built. Since it includes specific sample requirements, could you please inform me of your availability?"
                    style={{ marginBottom: '16px', borderTop: '0px', borderLeft: '0px', borderRight: '0px' }}
                >
                    <Input.TextArea 
                        rows={4} 
                        placeholder="You can add more details here if needed."
                    />
                </Form.Item>

                <Form.Item
                    label="Name"
                    name="name"
                    style={{ marginBottom: '16px' }}
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                   
                >
                    <Input 
                        prefix={<UserOutlined />} 
                        placeholder="Enter your name"
                         className='booking-input'
                    />
                </Form.Item>

                <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                        { required: true, message: 'Please enter your email!' },
                        { type: 'email', message: 'Please enter a valid email!' }
                    ]}
                    style={{ marginBottom: '16px' }}
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                   
                >
                    <Input 
                        prefix={<MailOutlined />} 
                        placeholder="Enter your email"
                        className='booking-input'
                    />
                </Form.Item>

                <Form.Item
                    label="Phone"
                    name="phone"
                    style={{ marginBottom: '16px' }}
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    
                >
                    <Input 
                        type="number"
                        prefix={<PhoneOutlined />} 
                        placeholder="Enter your phone number (optional)"
                        className='booking-input'
                    />
                </Form.Item>

                <Form.Item
                    label="Preferred Date"
                    name="bookingDate"
                    style={{ marginBottom: '16px' }}
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                >
                    <div className="custom-picker-wrapper">
                        <CalendarOutlined className="custom-icon" />
                        <DatePicker 
                            format="DD/MM/YYYY"
                            disabledDate={disabledDate}
                            style={{ width: '100%' }}
                            placeholder="Select a date"
                            inputReadOnly
                            className="custom-picker booking-input"
                            suffixIcon={null}  
                            onChange={handleDateChange}
                        />
                    </div>
                </Form.Item>

                <Form.Item
                    label="Preferred Time"
                    name="bookingTime"
                    style={{ marginBottom: '16px' }}
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                >
                    <div className="custom-picker-wrapper time-wrapper">
                        <ClockCircleOutlined className="custom-icon" />
                        <Select
                            options={timeOptions}
                            onChange={handleTimeChange}
                            placeholder="Select a time"
                            isClearable
                            isSearchable={false} 
                        
                            inputProps={{
                                readOnly: true // This will prevent the keyboard from opening
                            }}
                            styles={{
                                container: (provided) => ({
                                    ...provided,
                                    width: '100%'
                                }),
                                control: (provided) => ({
                                    ...provided,
                                    display: 'flex'
                                }),
                                valueContainer: (provided) => ({
                                    ...provided,
                                    paddingLeft: '24px'  // Adjust padding to accommodate the icon
                                }),
                                placeholder: (provided) => ({
                                    ...provided,
                                    marginLeft: '24px'  // Adjust placeholder position
                                }),
                            }}
                        />
                    </div>
                </Form.Item>


                <Form.Item style={{ textAlign: 'center' }}>
                    <Button type="primary" htmlType="submit" loading={confirmLoading} size="large" className="extra-large-button">
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default BookingModal;
