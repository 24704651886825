import React from 'react'
import { Drawer } from 'antd'
import { CloseOutlined, FacebookOutlined, InstagramOutlined, SearchOutlined, TwitterOutlined } from '@ant-design/icons'
import './index.css'

const MobileMenu = ({visible,onClose}) => {
  return (
    <Drawer
        title={()=>{}}
        placement={'top'}
        onClose={onClose}
        open={visible}
        height={'100%'}
        closeIcon={false}
        className='bg-drawer'
      >
        <button className="closeicon" onClick={onClose}>
          <CloseOutlined />
        </button>
        <div >
          <div className='d-flex justify-content-center mt-5 pt-5'>
            <img src="/logo.png" alt="" />
          </div>
          <div className='w-28rem'>
              <ul className='ul-menu'>
                <li>
                  <a href="#" className='text-uppercase'>
                    home
                  </a>
                </li>
                <li>
                  <a href="#" className='text-uppercase'>
                    Blood Testing
                  </a>
                </li>
                <li>
                  <a href="#" className='text-uppercase'>
                    Travel Clinic
                  </a>
                </li>
                <li>
                  <a href="#" className='text-uppercase'>
                    Treatment & Medication
                  </a>
                </li>
                <li>
                  <a href="#" className='text-uppercase'>
                    Contact
                  </a>
                </li>

              </ul>
              <div className='d-flex align-items-center justify-content-center gap-3 pt-4'>
                  <a href='#' className='social-icon'>
                      <FacebookOutlined />
                  </a>
                  <a href='#' className='social-icon'>
                      <TwitterOutlined />
                  </a>
                  <a href='#' className='social-icon'>
                      <InstagramOutlined />
                  </a>
              </div>
          </div>
        </div>
      </Drawer>
  )
}

export default MobileMenu