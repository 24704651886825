import React from 'react'

const HeaderForm = () => {
  return (
    <div className='py-4 px-4 mb-5'>
        <img src='/logo.png' width={105} />
    </div>
  )
}

export {HeaderForm}