import React from 'react'
import { Drawer } from 'antd'
import { CloseOutlined, FacebookOutlined, InstagramOutlined, SearchOutlined, TwitterOutlined } from '@ant-design/icons'
import './index.css'

const MobileMenu = ({visible,onClose}) => {
  return (
    <Drawer
        title={()=>{}}
        placement={'top'}
        onClose={onClose}
        open={visible}
        height={'100%'}
        closeIcon={false}
        className='bg-drawer'
      >
        <button className="closeicon" onClick={onClose}>
          <CloseOutlined />
        </button>
        <div >
          <div className='d-flex justify-content-center mt-5 pt-5'>
            <img src="/logo.png" alt="" />
          </div>
          <div className='w-28rem'>
              <ul className='ul-menu'>
                <li>
                  <a href="#" className='text-uppercase'>
                    home
                  </a>
                </li>
                <li>
                  <div class="accordion bg-transparent accordion-flush cust-accord" id="aboutaccord">
                    <div class="accordion-item bg-transparent">
                      <h2 class="accordion-header" id="flush-abouthead">
                        <button class="accordion-button collapsed bg-transparent border-0 p-0" type="button" data-bs-toggle="collapse" data-bs-target="#flush-about" aria-expanded="false" aria-controls="flush-about">
                          ABOUT
                        </button>
                      </h2>
                      <div id="flush-about" class="accordion-collapse collapse" aria-labelledby="flush-abouthead" data-bs-parent="#aboutaccord">
                        <div class="accordion-body py-2">
                          <ul className='ul-menu m-0'>
                            <li>
                              <a href="#" className='fs-16 text-uppercase'>
                                our mission
                              </a>
                            </li>
                            <li>
                              <a href="#" className='fs-16 text-uppercase'>
                                history
                              </a>
                            </li>
                            <li>
                              <a href="#" className='fs-16 text-uppercase'>
                                our team
                              </a>
                            </li>
                            <li>
                              <a href="#" className='fs-16 text-uppercase'>
                                our services
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <a href="#" className='text-uppercase'>
                    collections
                  </a>
                </li>
                <li>
                  <div class="accordion bg-transparent accordion-flush cust-accord" id="shopaccord">
                    <div class="accordion-item bg-transparent">
                      <h2 class="accordion-header" id="flush-shophead">
                        <button class="accordion-button collapsed text-uppercase bg-transparent border-0 p-0" type="button" data-bs-toggle="collapse" data-bs-target="#flush-shop" aria-expanded="false" aria-controls="flush-shop">
                          shop
                        </button>
                      </h2>
                      <div id="flush-shop" class="accordion-collapse collapse" aria-labelledby="flush-shophead" data-bs-parent="#shopaccord">
                        <div class="accordion-body py-2">
                          <ul className='ul-menu m-0'>
                            <li>
                              <a href="#" className='fs-16 text-uppercase'>
                                products
                              </a>
                            </li>
                            <li>
                              <a href="#" className='fs-16 text-uppercase'>
                                single product
                              </a>
                            </li>
                            <li>
                              <a href="#" className='fs-16 text-uppercase'>
                                cart
                              </a>
                            </li>
                            <li>
                              <a href="#" className='fs-16 text-uppercase'>
                                checkout
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <a href="#" className='text-uppercase'>
                    local stores
                  </a>
                </li>
                <li>
                  <div class="accordion bg-transparent accordion-flush cust-accord" id="pagesaccord">
                    <div class="accordion-item bg-transparent">
                      <h2 class="accordion-header" id="flush-pageshead">
                        <button class="accordion-button collapsed text-uppercase bg-transparent border-0 p-0" type="button" data-bs-toggle="collapse" data-bs-target="#flush-pages" aria-expanded="false" aria-controls="flush-pages">
                          pages
                        </button>
                      </h2>
                      <div id="flush-pages" class="accordion-collapse collapse" aria-labelledby="flush-pageshead" data-bs-parent="#pagesaccord">
                        <div class="accordion-body py-2">
                          <ul className='ul-menu m-0'>
                            <li>
                              <a href="#" className='fs-16 text-uppercase'>
                                tools
                              </a>
                            </li>
                            <li>
                              <a href="#" className='fs-16 text-uppercase'>
                                blog
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <a href="#" className='text-uppercase'>
                    contact
                  </a>
                </li>
              </ul>
              <div class="input-group custom-search mb-3">
                <input type="text" className="form-control border-0 p-0 fs-16 bg-transparent" placeholder="Search" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                <span className="input-group-text border-0 p-0 bg-transparent" id="basic-addon2">
                  <SearchOutlined />
                </span>
              </div>
              <div className='d-flex align-items-center justify-content-center gap-3 pt-4'>
                  <a href='#' className='social-icon'>
                      <FacebookOutlined />
                  </a>
                  <a href='#' className='social-icon'>
                      <TwitterOutlined />
                  </a>
                  <a href='#' className='social-icon'>
                      <InstagramOutlined />
                  </a>
              </div>
          </div>
        </div>
      </Drawer>
  )
}

export default MobileMenu