import { NavLink } from "react-router-dom";
import './index.css'
import {Typography} from  'antd'
const {Title}=Typography;
const BreadCrumb=({title,pageName,bgImg})=>
    {
        return(
            <div 
                style={{backgroundImage:`url(${bgImg})`,width:'100%',height:'500px',backgroundRepeat:'round'}}
                className='title flex-col-center-center py-5'
            >
                <div className='' style={{width:'93%',marginTop:'10.75rem',padding:'40px'}}>
                        <Title level={1} className='justify-center brand-color my-0' style={{fontSize:'4rem',fontWeight:'400',fontFamily:'Playfair Display'}}>{title}</Title>
                        <div className="justify-center" style={{marginTop:'15px'}}>
                            <Typography.Text>
                                <span className="mx-2 breadcrumb-link metropill">Home</span>
                                <span className="mx-2 breadcrumb-link" style={{verticalAlign:'bottom',fontSize:'25px',fontWeight:'100'}}> . </span>
                            </Typography.Text>
                            <Typography.Text   className="breadcrumb-link metropill">{pageName}</Typography.Text>
                        </div>
                </div>
            </div>
        )
    }
export {BreadCrumb}