import React, { useEffect, useState } from "react";
import "./index.css";
import { get } from "../../../services/ApiService.js";
import { Link } from "react-router-dom";
import BookingModal from "./BookingModal.jsx";
const Suggestion = ({ data, biomaker, selectedItems, resultRef, cart,AddingToCart, isAddingCart, isRequiresManualBooking,ManualBooking, confirmLoading,isMailSent, setIsMailSent }) => {
  const tableData = [];
  const [biomarkersList, setBiomarkersList] = useState([]);
  const FEE = 30;
  const isCartEmpty = !cart || cart === '' || cart === null;
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const calculateTotals = () => {
    let totalDiscount = 0;
    // Sum prices of all selected packages and biomarkers
    const packageSubtotal = data.reduce((acc, item, index) => {
      const isFirstItem = index === 0;
      const discountedPrice = isFirstItem && isCartEmpty ? item.price : Math.max(item.price - 10, 0);

      // Calculate discount
      if (!isFirstItem || !isCartEmpty) {
        if (discountedPrice < item.price) {
          totalDiscount += item.price - discountedPrice;
        }
      }

      return acc + item.price; // Original price for subtotal
    }, 0);

    const biomarkerSubtotal = biomaker.reduce((acc, item, index) => {
      const isFirstBiomarker = index === 0;
      const discountedPrice = data.length === 0 && isFirstBiomarker ? item.price : Math.max(item.price - 10, 0);

      // Calculate discount
      if (discountedPrice < item.price) {
        totalDiscount += item.price - discountedPrice;
      }

      return acc + item.price; // Original price for subtotal
    }, 0);

    // Calculate subtotal and total
    const subtotal = packageSubtotal + biomarkerSubtotal;
    const total = subtotal + FEE;

    return { subtotal, total, totalDiscount };
  };
  const { subtotal, total, totalDiscount } = calculateTotals();


  const fetchBiomarkers = async () => {
    try {
      const res = await get("api/package/biomarkers");
      if (res.success) {
        setBiomarkersList(res.biomarkers);
      } else {
        setBiomarkersList([]);
      }
    } catch (error) {
      setBiomarkersList([]);
    }
  };

  useEffect(() => {
    fetchBiomarkers();
  }, []);

//   useEffect(() => {
//     if (resultRef && resultRef.current) {
//       resultRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
//     }
//   }, [resultRef]);

  return (
    <div className="mt-5 px-lg-4" >
      <div className="row g-5" >
        {
          <div className="col-lg-12">
            <div
              className="card border-0 border-main rounded-4 shadow-c"
              style={{ marginTop: "60px" }}
            >
              <div className="card-body">
                <p className="metropill text-black fw-normal fs-14 mt-4">
                  Our Recommendation
                </p>
                
                {data &&
                  data.length > 0 &&
                  data.map((pack, index) => {

                    const duplicate = tableData.find((tableItem) => tableItem.key === pack._id);
                    if (duplicate) return null;
                    const { _id, name, biomarkers, additionalInformation,price } = pack;

                    const displayPrice = index === 0 && isCartEmpty ? price : Math.max(price - 10, 0);
                    const isDiscountApplied = displayPrice < price;

                    const biomarkerElements = biomarkers.map((item, bioIndex) => {
                        const itemExist = selectedItems.find(
                          (i) => i.id === item
                        );
                        let thisBio;
                        if (typeof item === "string") {
                          thisBio = biomarkersList.find(
                            (biomarker) => biomarker._id === item
                          );
                        }
                        const bioName =
                          typeof item === "string"
                            ? thisBio?.biomaker?.trim()
                            : item.biomaker;

                        return (
                          <div
                            class={`${
                              itemExist ? "tag-btnpro" : "tag-btn"
                            } text-decoration-none text-capitalize`}
                            key={index}
                            style={{cursor:'default'}}
                          >
                            {bioName || "Unknown Biomarker"}
                            
                          </div>
                        );
                      }
                    );
                    return (
                      <>
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="mt-5">
                            <h5 className="fw-semibold ">{name}</h5>
                          </div>
                          <h4 className="text-center">
                            {isDiscountApplied && (
                                <sup style={{fontSize:'15px', color:'#666363'}}>
                                    <del className=" del-line-color">£ {price} </del>
                                </sup>
                            )}
                            <span className="fw-semibold" style={{fontSize:'25px'}}>
                              <small className="">£{displayPrice}</small>
                            </span>
                          </h4>
                        </div>
                        <div className="col-12">
                            {pack.requiresManualBooking && 
                              <p className="metropill text-danger" style={{fontSize:'12px'}}>Due to the sample requirements on this test, please click below to send us an email</p>
                            }
                        </div>
                        <div className="mt-5 d-flex align-items-center gap-4  flex-wrap">
                          {biomarkerElements}
                        </div>
                        <hr />
                      </>
                    );
                  })
                }
                {biomaker &&
                  biomaker.length > 0 &&
                  biomaker.map((item, index) => {
                    const duplicate = tableData.find(
                      (tableItem) => tableItem.key === item._id
                    );
                    if (duplicate) return null;
                    const {
                      _id,
                      description,
                      biomaker: itemBiomaker,
                      price,
                    } = item;
                    const bioPrice = data.length === 0 && index === 0  ? price : Math.max(price - 10, 0);
                    const isDiscountApplied = bioPrice < price;
                    const itemExist = selectedItems.find(
                      (item) => item.id === _id
                    );
                    return (
                      <>
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="mt-5">
                            <h5 className="fw-semibold ">{itemBiomaker}</h5>
                          </div>
                          <h4 className=" text-center">
                            {isDiscountApplied && (
                                <sup  style={{fontSize:'15px', color:'#666363'}}>
                                    <del className=" del-line-color">£ {price} </del>
                                </sup>
                            )}
                            <span className="fw-semibold" style={{fontSize:'25px'}}>
                              <small className="">£{bioPrice}</small>
                            </span>
                          </h4>
                        </div>
                        <div className="col-12">
                            {item.requiresManualBooking && 
                              <p className="metropill text-danger" style={{fontSize:'12px'}}>Due to the sample requirements on this test, please click below to send us an email</p>
                            }
                        </div>
                        <div className="mt-5 d-flex align-items-center gap-4  flex-wrap">
                          <div
                            class={`${
                              itemExist ? "tag-btnpro" : "tag-btn"
                            } text-decoration-none text-capitalize`}
                            style={{cursor:'default'}}
                          >
                            {itemBiomaker}
                          </div>
                        </div>
                        <hr />
                      </>
                    );
                  })}
                <div className="row total-row mb-4">
                  <div className="col-lg-6 col-md-6 col-sm-12"></div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="cart-totals">
                    <div className="row total-row">
                      <div className="col-5 totel-left-heading"> <span>Subtotal</span></div>
                      <div className="col-7 totel-right-content"><span className="fs-18">  £ {subtotal}</span> </div>
                    </div>
                    <div className="row total-row">
                      <div className="col-5 totel-left-heading"> <span>Discount</span></div>
                      <div className="col-7 totel-right-content"><span className="fs-18"> - £ { totalDiscount}</span> </div>
                    </div>
                      <div className="row total-row">
                        <div className="col-5 totel-left-heading"><span>Blood Draw Fee</span> </div>
                        <div className="col-7 totel-right-content"><span className="fs-18">£ {FEE}</span> </div>
                      </div>
                    
                    <div className="row total-row">
                      <div className="col-5 totel-left-heading"><span>Total</span> </div>
                      <div className="col-7 totel-right-content"> <b className=" text-dark fs-18">£ {total}</b></div>
            
                    </div>
                  </div>

                  </div>
                </div>
                <div className="mt-5 pt-5">
                  {isRequiresManualBooking ? (
                    <>
                      <p className="metropill text-danger" style={{color:'#333',fontSize:'14px',textAlign:'center'}}>
                        Due to the sample requirements for one or more of your tests, please click here to email this package to our team. Once received, we will reach out to schedule your appointment according to the sample requirements
                      </p> 
                      <button type="button" className={`${isMailSent ? 'btn-gray':'btn-pink'} w-100`}  onClick={showModal} disabled={isMailSent}>
                      {isMailSent ? 'Email Sent':'Send Email' } 
                    </button>
                    </>              
                  ):(
                    <button type="button" className="btn-pink w-100" onClick={AddingToCart} disabled={isAddingCart}>
                      {isAddingCart && <i className="fas fa-spinner fa-spin"></i>} Add to cart
                    </button>
                  )
                  }

                </div>
              </div>
            </div>
          </div>
        }
      </div>
      <BookingModal
        isVisible={isModalVisible}
        onClose={handleCancel}
        ManualBooking={ManualBooking}
        confirmLoading={confirmLoading}
      />
    </div>
  );
};

export { Suggestion };
