import React, { useState } from 'react'
import '../index.css'

const StepOne = () => {

    const [ showinput, setShowInput ] = useState(false)
    const [ checked, setChecked ] = useState(false)


  return (
    <>
        <div className='row g-5'>
            <div className='col-lg-12'>
                <h5 className='mb-5'>
                    What are your main reasons for wanting to lose weight?
                </h5>
            </div>
            <div className='col-lg-12'>
                <input type="radio" className='d-none' name='samename' id='one' />
                <label className={showinput === 1 ? 
                    'padc bullet active-check w-100 rounded-3 d-flex align-items-center gap-3' : 
                    'padc bullet w-100 d-flex align-items-center gap-3 rounded-3' } 
                    onClick={()=>setShowInput(1)}
                    for='one'
                >   
                    <div  className='label-cs'></div>
                    <span class="metropill">
                        Improving overall health
                    </span>
                </label>                
            </div>
            <div className='col-lg-12'>
                <input type="radio" className='d-none' name='samename' id='two' />
                    <label className={showinput === 2 ? 
                        'padc bullet active-check w-100 rounded-3 d-flex align-items-center gap-3' : 
                        'padc bullet w-100 d-flex align-items-center gap-3 rounded-3' } 
                        onClick={()=>setShowInput(2)}
                        for='two'
                    >   
                        <div  className='label-cs'></div>
                        <span class="metropill">
                            Other things
                        </span>
                    </label> 
                </div>
            <div className={ showinput === 1 ? 'col-lg-12 animatecs fade-inn active' : 'col-lg-12 animatecs fade-outt'}>
                <div>
                    <label for="note" class="form-label metropill">Write Specify note</label>
                    <textarea class="form-control border-0" id="note" rows="3"></textarea>
                </div>
            </div>
        </div>
    </>
  )
}

export {StepOne}