import React, { useState } from 'react';
import '../index.css';

const Stepthree = () => {

  const [checkedOne, setCheckedOne] = useState(false);
  const [checkedTwo, setCheckedTwo] = useState(false);
  const [checkedThree, setCheckedThree] = useState(false);

  return (
      <div className='row g-5'>
        <div className='col-lg-12'>
          <h5 className='mb-5'>
            Hey tell me something about yourself!
          </h5>
        </div>
        <div className='col-lg-12'>
          <input type="checkbox" className='d-none' id='one' />
          <label className={checkedOne ? 
              'padc bullet active-check w-100 rounded-3 d-flex align-items-center gap-3' : 
              'padc bullet w-100 d-flex align-items-center gap-3 rounded-3' } 
              onClick={() => setCheckedOne(!checkedOne)} 
              for='one'
          >   
              <div className='label-check'></div>
              <span class="metropill">
                Here is the third step
              </span>
          </label>
        </div>
        
          <div className='col-lg-12'>
            <input type="checkbox" className='d-none' id='two' />
            <label className={checkedTwo ? 
              'padc bullet active-check w-100 rounded-3 d-flex align-items-center gap-3' : 
              'padc bullet w-100 d-flex align-items-center gap-3 rounded-3' } 
              onClick={() => setCheckedTwo(!checkedTwo)} 
              for='two'
            >   
              <div className='label-check'></div>
              <span class="metropill">
                Other
              </span>
            </label>
          </div>
          <div className={ checkedOne ? 'animatecs fade-inn active' : 'animatecs fade-outt'}>
              <div className='mt-30'></div>
              <div className='col-lg-12'>
                <h5 className='mb-5'>
                  What are your main reasons for wanting to lose weight?
                </h5>
              </div>
              <div className='col-lg-12'>
                <input type="checkbox" className='d-none' id='three' />
                <label className={checkedThree ? 
                  'padc bullet active-check w-100 rounded-3 d-flex align-items-center gap-3' : 
                  'padc bullet w-100 d-flex align-items-center gap-3 rounded-3' } 
                  onClick={() => setCheckedThree(!checkedThree)} 
                  for='three'
                >   
                  <div className='label-check'></div>
                  <span class="metropill">
                    Are you ok?
                  </span>
                </label>
              </div>
          </div>
      </div>
  );
}

export { Stepthree };
