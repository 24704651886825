import React, { useState, useEffect } from 'react';
import './Datepicker.css'; // Ensure you have this CSS file for styling
import moment from 'moment';

const BookingDatePicker = ({
  startDate,
  handleDateSelect,
  currentDate,
  containerRef,
  
}) => {
  const [selectedDate, setSelectedDate] = useState(startDate || new Date());
  const [displayedMonth, setDisplayedMonth] = useState(
    new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 1)
  );

  useEffect(() => {
    if (startDate) {
      setSelectedDate(startDate);
      setDisplayedMonth(new Date(startDate.getFullYear(), startDate.getMonth(), 1));
    }
  }, [startDate]);

  const daysInMonth = (month, year) => new Date(year, month + 1, 0).getDate();

  const handleDateClick = (day) => {
    const newDate = new Date(
      displayedMonth.getFullYear(),
      displayedMonth.getMonth(),
      day
    );
    if (newDate >= currentDate && !isWeekend(newDate)) {
      setSelectedDate(newDate);
      handleDateSelect(newDate);
    }
  };

  const isWeekend = (date) => {
    const day = date.getDay();
    return day === 6 || day === 0; // Saturday = 6, Sunday = 0
  };

  const renderDays = () => {
    const days = [];
    const totalDays = daysInMonth(displayedMonth.getMonth(), displayedMonth.getFullYear());
    const startDay = new Date(displayedMonth.getFullYear(), displayedMonth.getMonth(), 1).getDay();
    const today = new Date();

    // Calculate previous month days to show at the start
    const previousMonthLastDay = new Date(displayedMonth.getFullYear(), displayedMonth.getMonth(), 0).getDate();
    const leadingDays = startDay === 0 ? 6 : startDay - 1; // Adjust for Monday start

    // Add previous month's last few days
    for (let i = leadingDays - 1; i >= 0; i--) {
      const day = previousMonthLastDay - i;
      days.push(
        <div key={`prev-${day}`} className="prev-month-day disabled">
          {day}
        </div>
      );
    }

    // Add current month's days
    for (let i = 1; i <= totalDays; i++) {
      const currentDate = new Date(displayedMonth.getFullYear(), displayedMonth.getMonth(), i);
      const isPastDate = currentDate < today;
      const weekend = isWeekend(currentDate);

      days.push(
        <div
          key={i}
          className={`day ${
            i === selectedDate.getDate() &&
            displayedMonth.getMonth() === selectedDate.getMonth()
              ? 'selected'
              : ''
          } ${isPastDate || weekend ? 'disabled' : ''}`}
          onClick={() => !isPastDate && !weekend && handleDateClick(i)}
        >
          {i}
        </div>
      );
    }

    return days;
  };

  const handleMonthChange = (direction) => {
    setDisplayedMonth(
      new Date(displayedMonth.getFullYear(), displayedMonth.getMonth() + direction, 1)
    );
  };

  return (
    <div className="date-picker" ref={containerRef}>
      <div className="calendar">
        <div className="month">
          <div className="arrow" onClick={() => handleMonthChange(-1)}>
            <img src="/assets/images/arrow-left.png" alt="" style={{ width: '30px', opacity: '0.5' }} />
          </div>
          <div className="monthName">
            {displayedMonth.toLocaleString('default', { month: 'long' })}{' '}
            {displayedMonth.getFullYear()}
          </div>
          <div className="arrow" onClick={() => handleMonthChange(1)}>
            <img src="/assets/images/arrow-right.png" alt="" style={{ width: '30px', opacity: '0.5' }} />
          </div>
        </div>
        <div className="weekdays">
          <div>M</div>
          <div>T</div>
          <div>W</div>
          <div>T</div>
          <div>F</div>
          <div>S</div>
          <div>S</div>
        </div>
        <div className="days">{renderDays()}</div>
      </div>
    </div>
  );
};

export default BookingDatePicker;
