import React, { useState } from 'react';

const TextWithReadMore = ({ text = '', wordLimit }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  // Check if the text is empty or not defined
  if (!text || text.length === 0) {
    return null; // Or you can return some default fallback UI
  }

  const truncatedText = text.length > wordLimit ? text.slice(0, wordLimit) + '..' : text;

  return (
    <span className='metropill'>
      {isExpanded ? text : truncatedText}
      {text.length > wordLimit && (
        <span
          className="read-more metropill"
          onClick={handleToggle}
          style={{ color: '#e2b9b3', cursor: 'pointer', fontSize: '15px', fontWeight: '600' }}
        >
          {isExpanded ? 'Read less' : 'Read more'}
        </span>
      )}
    </span>
  );
};

export default TextWithReadMore;
