import React, { useState, useEffect } from 'react';
import { useParams,useNavigate,useLocation } from 'react-router-dom';
import DataService from '../config/DataService';
import CartLoader from "../components/CartLoader";
import { get, post } from '../services/ApiService.js';
import Header from "../components/Header";
import Footer from "../components/Footer";
import Navbar from '../components/Navbar/index.jsx';
const generateRandomToken = () => {

    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const length = 32; 
  
    let token = '';
    for (let i = 0; i < length; i++) {
      token += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return token;
  
};

const AddToCart = () => {
    const navigate = useNavigate();
    const { uniqueCode } = useParams();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const category = queryParams.get('category');
    const [userToken, setUserToken] = useState('');
    const [cartItems, setCartItems] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setError] = useState(false);

    useEffect(() => {

        const token = localStorage.getItem('userToken');
        if (!token) {
            const newToken = generateRandomToken();
            localStorage.setItem('userToken', newToken);
            setUserToken(newToken);
        } else {
            setUserToken(token);
        }


    }, [uniqueCode]);


    useEffect(() => {
        
        if (userToken && uniqueCode) {
            AddingToCart();
        }
        // console.log('user token:'+userToken)
        // console.log('unique code:'+uniqueCode)
        // console.log('category is:'+ category)
    }, [userToken, uniqueCode]);

    const AddingToCart = async () => {
        setIsLoading(true)
        try {
            const Body = {
                userToken,
                uniqueCode,
                category
            };
            setIsLoading(true);
            const res = await post(`api/cart/add-to-cart`, Body);
            if (res?.success) {
               setIsLoading(false)

                navigate('/cart');
            } else {
                setIsLoading(false);
                setError(true)
                console.log('Failed to add item in cart');
            }
        } catch (error) {
            setError(true)
            setIsLoading(false);
            console.error(error);
        }
    };


    return (
        <>
             <Navbar />
             
            <section className="container">
                <div className="shopping-cart">
                    <h2 className="text-center fs-2 mt-5 mb-5" style={{ fontWeight: 'bold', fontFamily: 'Playfair Display, serif' }}>Your Basket</h2>
                    <CartLoader />
                    {isError && 
                        <div className='card'>
                            <div className='card-body text-center p-4'>
                                <p>Couldn't proccess your request. Someting went wrong</p>
                                <button className='btn btn-primary'>Try Again</button>
                            </div>
                        </div>
                    }

                </div>
            </section>
        </>
    );
};

export default AddToCart;
