import React, { useState } from 'react'
import './index.css'
import { Link } from 'react-router-dom'

const Sidebar = ({selectedItems, buildPkgRecomendation, isLoading, isRecommending, setIsRecommending, handleRemove}) => {

  return (
    <div className='bg-filter'>
        <div className='p-5'>
            <div className='pt-5' >
                <h5 style={{marginTop:'20px',marginBottom:'30px'}}>Selected Items</h5>
                <div className='d-flex align-items-center flex-wrap mt-5 gap-6s mb-5 side-collapse' >
                    {selectedItems.length > 0 ? (
                        selectedItems.map((item, index) => (
                        <div class={`${item.type === 'bio'? 'tag-btn':'tag-btn-green'} text-decoration-none gap-3 text-capitalize`} key={index}> 
                            {item?.name}
                            <Link onClick={() => handleRemove(item.id)}><i className='fa fa-close'></i></Link>
                        </div>
                        ))
                    ):(
                        <p>No item selected yet!</p>
                    )}
 
                </div>
            </div>
            

            {selectedItems.length > 0 &&
                <>
                {isRecommending ? (
                    <button type='button' className='btncust' onClick={()=>{setIsRecommending(false)}} >
                         Edit My Package
                    </button>
                ):(
                    <button type='button' className='btncust' onClick={buildPkgRecomendation} disabled={isLoading}>
                        {isLoading && <i className='fa fa-spinner fa-spin'></i>}   Build My Package
                    </button>
                )}

                </>
            }


        </div>        
    </div>
  )
}

export {Sidebar}