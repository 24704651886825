
import React from 'react';
import './index.css'
function TimeSlots({ selectedTimeSlot, handleTimeSlotSelect,timeSlots }) {
    const formatTime = (timeString) => {
        const [hours, minutes] = timeString.split(':');
        return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;
    };
 
    return (
        <>
        {timeSlots.length > 0 ? (
            <div data-testid="timeTableContainer" className="slots-container">
                {timeSlots.map(timeSlot => (
                    <button type="button"  className={`btn-slot metropill ${selectedTimeSlot === timeSlot ? "active" : ""}`}  onClick={() => handleTimeSlotSelect(timeSlot)}>{formatTime(timeSlot)}</button>
                ))}
            </div>
        ) : (
            <div className="no-time-slots-message">
                No time slots available.
            </div>
        )}
      </>
    );
}

export default TimeSlots;
