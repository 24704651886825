import React from 'react';
import { Link } from "react-router-dom";
import ContentLoader,{List,BulletList} from "react-content-loader"
const CartLoader = () => {
  return (
    <>
        <div className="row p-8">
          <div className="col-lg-8 col-md-8 col-sm-12">
            <div className="col-12">
                <ContentLoader speed={1} width={600} height={120} foregroundColor="#FFE8DC">
                    <rect x="0" y="0" rx="5" ry="5" width="100" height="100" />
                    <rect x="110" y="10" rx="2" ry="2" width="40" height="20" />
                    <rect x="110" y="35" rx="4" ry="4" width="470" height="13" /> 
                    <rect x="110" y="58" rx="3" ry="3" width="380" height="30" />   
                </ContentLoader>
                <ContentLoader speed={2} width={600} height={120} foregroundColor="#FFE8DC">
                    <rect x="0" y="0" rx="5" ry="5" width="100" height="100" />
                    <rect x="110" y="10" rx="2" ry="2" width="40" height="20" />
                    <rect x="110" y="35" rx="4" ry="4" width="470" height="13" /> 
                    <rect x="110" y="58" rx="3" ry="3" width="380" height="30" />   
                </ContentLoader>
                <ContentLoader speed={2} width={600} height={100} foregroundColor="#FFE8DC">
                    <rect x="0" y="0" rx="5" ry="5" width="100" height="100" />
                    <rect x="110" y="10" rx="2" ry="2" width="40" height="20" />
                    <rect x="110" y="35" rx="4" ry="4" width="470" height="13" /> 
                    <rect x="110" y="58" rx="3" ry="3" width="380" height="30" />  
                </ContentLoader>
            </div>
          </div>
          <div className="col-lg-4 col-md-8 col-sm-12 pt-lg-0 pt-11">
            <div className='col-12'>
                <ContentLoader speed={1} width={400} height={300}>
                    <rect x="0" y="0" rx="8" ry="8" width="400" height="300" />
                </ContentLoader>
            </div>

            <div className='col-12 mt-4'>
                <ContentLoader speed={2} width={400} height={210}>
                    {/* Box outline */}
                    <rect x="0" y="0" rx="8" ry="8" width="400" height="200" />
                </ContentLoader>
            </div>
  
          </div>
        </div>

    </>
  )
}

export default CartLoader