import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom';
import  './index.css'
import MobileMenu from './MobileMenu';

const Navbar = ({setSearchValue, searchValue}) => {

    const [ visible, setVisible ] = useState(false)

    useEffect(() => {
        const navbar = document.getElementsByClassName("chemist-navbar")[0];
        const navbarLeft = document.getElementsByClassName("chemist-navbar-left");
        const navbarSmall = document.getElementsByClassName("chemist-navbar-small")[0];

        const responsive = () => {
            // Add responsive logic here if necessary
        };

        responsive();
        window.addEventListener("resize", () => {
            responsive();
            scrol();
        });

        let upDown;

        const scrol = () => {
            console.log(window.pageYOffset);
            if (window.innerWidth > 970) {
                if (window.pageYOffset > upDown) {
                    navbar.setAttribute("class", "chemist-navbar animation chemist-navbar-move");
                } else if (window.pageYOffset < 11) {
                    navbar.setAttribute("class", "chemist-navbar animation bg-transparent");
                } else {
                    navbar.setAttribute("class", "chemist-navbar animation secondary-bg chemist-navbar-top0");
                }
            } else {
                navbar.setAttribute("class", "chemist-navbar");
                if (window.pageYOffset > upDown) {
                    navbarSmall.setAttribute("class", "chemist-navbar-small secondary-bg animation chemist-navbar-small-move");
                } else if (window.pageYOffset < 11) {
                    navbarSmall.setAttribute("class", "chemist-navbar-small transparent-bg animation");
                } else {
                    navbarSmall.setAttribute("class", "chemist-navbar-small secondary-bg animation");
                }
            }
            upDown = window.pageYOffset;
        };

        scrol();
        window.onscroll = function() { scrol(); };

        return () => {
            window.removeEventListener("resize", () => {
                responsive();
                scrol();
            });
            window.onscroll = null;
        };
    }, []);



        
        return  (
            <>
           <div  className='chemist-navbar-container' style={{position:'relative'}}>
           <div className='w-100'>
                    <div className="chemist-navbar-small secondary-bg">
                        <div className="chemist-navbar-inner flex-column bg-white">
                            <div className='d-flex align-items-center justify-content-between'>
                                <div className='chemist-navbar-left'><img src={'/logo.png'} width={'93px'} alt=''/></div>
                                <div className="chemist-navbar-right d-flex align-items-center gap-2">
                                    <div className="sp-cover" onClick={()=>setVisible(true)}>
                                        <span className="sp"></span>
                                    </div>
                                    <NavLink to="/" className="cart-nav">
                                        <img src="/assets/images/header/cart-ic.png" className='cartone' width={24} alt="" />
                                        <img src="/assets/images/header/cart-ic-hover.png" className='carttwo' width={24} alt="" />
                                    </NavLink>
                                </div>
                            </div>
                            <div className='mt-5 d-block d-lg-none'>
                                <div class="input-group search-input">
                                    <input name="search" type="search" class="form-control" placeholder="Search Here" value={searchValue} onChange={(event)=>{setSearchValue(event.target.value)}} />
                                    <div class="input-group-addon">
                                        <label for='searchid' class="border-0">
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M9.58366 17.5C13.9559 17.5 17.5003 13.9556 17.5003 9.58335C17.5003 5.2111 13.9559 1.66669 9.58366 1.66669C5.21141 1.66669 1.66699 5.2111 1.66699 9.58335C1.66699 13.9556 5.21141 17.5 9.58366 17.5Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                                <path d="M18.3337 18.3334L16.667 16.6667" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                            </svg>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='chemist-navbar'>
                    <div className="chemist-navbar-inner px-3">
                        <div className='chemist-navbar-left'>
                            <img src='/logo.png' width={'93px'} preview={false} className='one'/>
                            <img src='/assets/close.png' preview={false} className='two'/>
                        </div>
                        <ul className="chemist-navbar-middle" style={{height:'100%'}}>
                            <li className='items'>
                                <NavLink to="/" className="nav-item">
                                    <span className='item-name metropill'>HOME</span>
                                </NavLink>
                            </li>
                            <li className='items'>
                                <NavLink to="/" className="nav-item">
                                    <span className='item-name metropill'>BLOOD TESTING</span>
                                </NavLink>
                            </li>
                            <li className='items'>
                                <NavLink to="/" className="nav-item">
                                    <span className='item-name metropill'>TRAVEL CLINIC</span>
                                </NavLink>
                            </li>
                            <li className='items first-hide'>
                                <NavLink to="/" className="nav-item">
                                    <span className='item-name metropill'>TREATMENT MEDICATION</span>
                                </NavLink>
                            </li>

                            <li className='items second-hide'>
                                <NavLink to="/" className="nav-item">
                                    <span className='item-name metropill'>CONTACT</span>
                                </NavLink>
                            </li>


                            <li className='items'>
                                <NavLink to="/" className="cart-nav">
                                    <img src="/assets/images/header/cart-ic.png" className='cartone' width={24} alt="" />
                                    <img src="/assets/images/header/cart-ic-hover.png" className='carttwo' width={24} alt="" />
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <MobileMenu 
                visible={visible}
                onClose={()=>setVisible(false)}
            />
        </>
        )
    }
export default Navbar;
