import React from "react";

const BmiBar = ({ bmiValue }) => {
  const getIndicatorPosition = (bmi) => {
    let position;

    if (bmi < 18.5) {
      position = (bmi / 18.5) * 9;
    } else if (bmi >= 18.5 && bmi < 25) {
      position = ((bmi - 18.5) / (25 - 18.5)) * (39 - 9) + 9;
    } else if (bmi >= 25 && bmi < 30) {
      position = ((bmi - 25) / (30 - 25)) * (69.5 - 39) + 39;
    } else {
      position = ((bmi - 30) / (40 - 30)) * (100 - 69.5) + 69.5;
    }

    return `${Math.min(Math.max(position, 0), 100)}%`;
  };

  return (
    <div className="container" style={{ paddingLeft: '30px', paddingRight: '30px' }}>
      <div className="row">
        <div className="col-12">
          <div className="segment-bar">
            <div className="segment underweight" />
            <div className="segment healthy" />
            <div className="segment overweight" />
            <div className="segment obese" />
            <div
              className="indicator"
              style={{ left: getIndicatorPosition(bmiValue) }}
            />
          </div>
          <div className="labels">
            <span className="label underweight-label"></span>
            <span className="label healthy-label">Healthy</span>
            <span className="label overweight-label">Overweight</span>
            <span className="label obese-label">Obese</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BmiBar;
