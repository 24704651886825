import React from 'react'
import { Flex } from 'antd'
import './index.css'
import Navbar from '../../components/Navbar'

const DummyPage = () => {
  return (
    <div>
        <div className='bg-banner'>
            <Navbar />
        </div>
        <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. In dolore quam deserunt temporibus, nemo vero nisi et delectus porro! Ullam, architecto at! Esse consequuntur veritatis fugit asperiores reprehenderit, quam iusto? Lorem ipsum dolor sit amet, consectetur adipisicing elit. In dolore quam deserunt temporibus, nemo vero nisi et delectus porro! Ullam, architecto at! Esse consequuntur veritatis fugit asperiores reprehenderit, quam iusto? Lorem ipsum dolor sit amet, consectetur adipisicing elit. In dolore quam deserunt temporibus, nemo vero nisi et delectus porro! Ullam, architecto at! Esse consequuntur veritatis fugit asperiores reprehenderit, quam iusto?
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. In dolore quam deserunt temporibus, nemo vero nisi et delectus porro! Ullam, architecto at! Esse consequuntur veritatis fugit asperiores reprehenderit, quam iusto?
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. In dolore quam deserunt temporibus, nemo vero nisi et delectus porro! Ullam, architecto at! Esse consequuntur veritatis fugit asperiores reprehenderit, quam iusto?
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. In dolore quam deserunt temporibus, nemo vero nisi et delectus porro! Ullam, architecto at! Esse consequuntur veritatis fugit asperiores reprehenderit, quam iusto?
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. In dolore quam deserunt temporibus, nemo vero nisi et delectus porro! Ullam, architecto at! Esse consequuntur veritatis fugit asperiores reprehenderit, quam iusto?
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. In dolore quam deserunt temporibus, nemo vero nisi et delectus porro! Ullam, architecto at! Esse consequuntur veritatis fugit asperiores reprehenderit, quam iusto?
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. In dolore quam deserunt temporibus, nemo vero nisi et delectus porro! Ullam, architecto at! Esse consequuntur veritatis fugit asperiores reprehenderit, quam iusto?
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. In dolore quam deserunt temporibus, nemo vero nisi et delectus porro! Ullam, architecto at! Esse consequuntur veritatis fugit asperiores reprehenderit, quam iusto?

            Lorem ipsum dolor sit amet, consectetur adipisicing elit. In dolore quam deserunt temporibus, nemo vero nisi et delectus porro! Ullam, architecto at! Esse consequuntur veritatis fugit asperiores reprehenderit, quam iusto?
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. In dolore quam deserunt temporibus, nemo vero nisi et delectus porro! Ullam, architecto at! Esse consequuntur veritatis fugit asperiores reprehenderit, quam iusto?
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. In dolore quam deserunt temporibus, nemo vero nisi et delectus porro! Ullam, architecto at! Esse consequuntur veritatis fugit asperiores reprehenderit, quam iusto?

        </p>
    </div>
  )
}

export default DummyPage